@import "../../styles/variables.scss";

.nav-header {
  display: flex;
  align-items: center;
  background-color: transparent;
  a {
    text-decoration: none;
  }
}

.nav-link {
  margin-right: 10px;
  background-color: #515657;
  border-radius: 15px 15px 0 0;
  transition: $transition;
  span {
    color: $white;
    text-decoration: none;
  }
}

.nav-icon {
  color: $primary-color;
}

.nav-header-tab {
  min-width: 120px;
  transition: $transition;
  border-radius: 12% / 50%;
  background-color: #f6f6f6;
  padding: 10px 20px 10px 20px;
  margin-right: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  span {
    color: #010101;
    text-decoration: none;
  }
}

.nav-link.active {
  background-color: $primary-color;
}

.nav-link:hover,
.nav-header-tab:hover {
  background-color: $primary-highlight;
}
