@import "../../../styles/variables.scss";


.task-count {
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: rgb(190, 189, 189);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: .5rem;
    &.complete {
        background-color: $primary-color;
    }

}

.task-document-container {
    background: #F7F7F7;
    border-radius: $radius;
    border: solid 1px transparent;
    cursor: pointer;
    transition: $transition;
}

.task-document-container-disabled {
  background: #F7F7F7;
  border-radius: $radius;
  border: solid 1px lightgray;
  cursor: not-allowed;
}

.task-document-container:hover {
  border-color: $primary-highlight;
  background: #F1F7F7;
}

.document-drop-container {
    padding-bottom: 10px;
}

@media screen and (max-width: 1600px) {
  .task-document-container {
    margin-right: 0px;
}
}

@media screen and (max-width: 767px) {
  .task-document-container {
    margin-left: 0px;
}
}