@import "../../styles/variables.scss";
.notes_container {
  display: flex;
  min-height: 64vh;
  border-radius: $radius;
  overflow: hidden;
}

.notes_empty {
  color: lightgray;
  font-style: italic;
  user-select: none;
}

.notes_list {
  width: 25%;
  background-color: $primary-color;

  &__item {
    min-height: 100px;
    background-color: $primary-highlight;
    padding: 7px;
    font-size: 14px;
    margin-bottom: 1px;

    &_title {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 3px;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      line-height: 14px;
    }
    &_body {
      font-size: 12px;
      line-height: 13px;
    }
  }

  &__item:hover {
    cursor: pointer;
    background-color: $primary-highlight-3;
    transition: 0.2s;
  }

  &__item-active {
    background-color: $primary-highlight-2;
  }
}

.notes_viewer {
  width: fill;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  color: $gray-7;

  &__title {
    border: none;
    color: $gray-9;
    margin-bottom: 6px;
    padding: 0px;
  }

  &__title:focus {
    outline: none;
  }

  &__createdAt {
    font-weight: bold;
  }

  &__updatedAt {
    font-style: italic;
    color: $primary-color;
  }

  &__body {
    padding-top: 24px;
    border: none;
    height: fill;
    resize: none;
    color: $gray-9;
  }

  &__body:focus {
    outline: none;
  }
}

.trash-hover:hover {
  color: $primary-highlight-3;
  background-color: $primary-color;
  border-radius: 100%;
  padding: 2px;
  transform: scale(2);
  cursor: pointer;
}

// .notes_pagination {
//   &__button {
//     border-radius: 100%;
//     height:30px;
//     width: 30px;
//     background-color: $primary-highlight;
//     border: none;
//     margin-right: 7px;
//     color: $primary-color;
//   }

//   &__button:hover {
//     background-color: $primary-highlight-2;
//   }

//   &__text {
//     color: $primary-highlight-3;
//     text-align: right;
//     font-size: 12px;
//     text-transform: uppercase;
//     padding-right: 8px;
//   }
// }



// --- Placeholder Text Color ---
textarea::placeholder, input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: lightgray;
  font-style: italic;
  opacity: 1; /* Firefox */
}

textarea::placeholder, input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: lightgray;
  font-style: italic;
}

textarea::placeholder, input::-ms-input-placeholder { /* Microsoft Edge */
  color: lightgray;
  font-style: italic;
}