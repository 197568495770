.transaction-details-container {
  
}

.transaction-details-subheader {
  width: 100%;
  width: -moz-fit-content;
  width: fit-content;
  color: black;
  font-family: Arial;
  border-radius: 0.5em;
}

.step-container {
  width: 100%;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
}

.label {
  color: white;
  padding: 4px;
  font-family: Arial;
  border-radius: 0.5em;
}

#step-pass {
  background-color: #117201;
} 
#step-review {
  background-color: #ff9800;
} 
#step-fail {
  background-color: #cd0e00;
} 
#step-error
 {
  background-color: #878080;
}

#status-pass{
  background-color: #117201;
  color: white;
  padding: 4px;
  font-family: Arial;
  border-radius: 0.5em;
}

#status-review{
  background-color: #ff9800;
  color: white;
  padding: 4px;
  font-family: Arial;
  border-radius: 0.5em;
}

#status-fail{
  background-color: #cd0e00;
  color: white;
  padding: 4px;
  font-family: Arial;
  border-radius: 0.5em;
}

#status-error{
  background-color: #878080;
  color: white;
  padding: 4px;
  font-family: Arial;
  border-radius: 0.5em;
}

table,
th,
td {
  border: 1px solid black;
  text-align: left;
}
