@import "../../styles/variables.scss";

.application-table-control-container {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid grey;
}

.application-table {
  background: $white;
  min-height: 60vh;
  border-radius: $radius;
  align-items: flex-start;

  .table thead th {
    vertical-align: middle !important;
  }

  tbody tr {
    td {
      vertical-align: middle;
    }
    margin-bottom: 5px;
    .cta-arrow {
      opacity: 0;
      color: $primary-color;
    }

    .new-cta {
      color: $primary-color;
      span {
        position: relative;
      }
    }

    &:hover {
      cursor: pointer;

      background-color: #42d3c030 !important;

      .cta-arrow {
        opacity: 1;
      }
    }
  }
}

#application-detail {
  height: 100%;
}

.detail-sidebar {
  background-color: #f6f6f6;
  color: #010101;

  .meta-logo {
    font-size: 12px;
    color: #b3b4b4;
  }
  .meta {
    font-size: 12px;
    color: #a2b0bc;
  }
}
.sidebar-section {
  display: flex;
  flex-direction: column;
}

.sidebar-sub-section {
  margin-bottom: 12px;
}

.sidebar-sub-section:not(:first-of-type) {
  border-top: 1px solid #dee2e6;
}

.response-step-field {
  padding-top: 1px;
  margin-bottom: 4px;
  border-top: 1px solid #dee2e6;
}

.sidebar-logo-container {
  display: flex;
  background-color: white;
  padding: 0.5em;
  margin-bottom: 1rem;
}

.activity-circle-icon {
  color: $primary-color;
  font-size: 1em;
}

.activity-feed-item {
  background: $white;
  padding: 0.8rem;
  border-radius: $radius;
  border: 1px solid $primary-color;
}

.application-navigation {
  padding-top: 40px;
  padding-left: 17px;
  display: flex;

  background: #f6f6f6;
  a {
    text-decoration: none;
  }
}

.documents-container {
  background: white;
  height: calc(100% - (65px));
}

.content-container {
  background: white;
  height: calc(100% - (65px));
  border-radius: $radius;
}

@media screen and (max-width: 767px) {
  .sidebar-status {
    width: 100%;
  }

  .sidebar-status {
    width: 100%;
  }
}
