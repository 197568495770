ul.decision-details {
  /* No normal bullets please */
  list-style-type: none;

  li {
    /* You need to turn on relative positioning so the line is placed relative to the item rather than absolutely on the page */
    position: relative;
    
    /* Use padding to space things out rather than margins as the line would get broken up otherwise */
    margin: 0;
    padding-bottom: 1em;
    padding-left: 40px;
  }

  /* The actual line being placed before each list item, tweak width and color as appropriate */
  li:before {
    content: '';
    width: 2px;
    position: absolute;
    left: 5px;
    top: 4px;
    bottom: -4px;
    background-color: #000;
  }
  li.hit:before {
    top: 16px;
  }
  li.approved:before {
    background-color: #00b4a1;
  }
  li.review:before {
    background-color: #e2832c;
  }
  li.denied:before {
    background-color: #eb2835;
    top: 16px;
  }
  li.skipped:before {
    background-color: #cfcfcf;
  }
      
  /* Start the line further down on the first list item */
  li:first-child:before { top: 11px;  }
  li.hit:first-child:before { top: 15px;  }

  /* Stop the line short on the final list item */
  li:last-child:before { height: 6px; }
  li.hit:last-child:before { height: 0px; }

  /* Small bullets for normal list items */
  li::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23000' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
  }
  li.approved::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%2300b4a1' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");

  }
  li.review::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23e2832c' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
    
  }
  li.denied::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23eb2835' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
    
  }
  li.skipped::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23cfcfcf' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
    
  }

  /* Large bullet for hit items */
  li.hit::after {
    width: 16px;
    height: 16px;
    left: -2px;
    top: 2px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Cpath d='M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z' fill='%23000'%3E%3C/path%3E%3Ccircle fill='%23000' cx='16' cy='16' r='6'%3E%3C/circle%3E%3C/svg%3E");
  }
  li.hit.approved::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Cpath d='M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z' fill='%2300b4a1'%3E%3C/path%3E%3Ccircle fill='%2300b4a1' cx='16' cy='16' r='6'%3E%3C/circle%3E%3C/svg%3E");

  }
  li.hit.review::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Cpath d='M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z' fill='%23e2832c'%3E%3C/path%3E%3Ccircle fill='%23e2832c' cx='16' cy='16' r='6'%3E%3C/circle%3E%3C/svg%3E");
    
  }
  li.hit.denied::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Cpath d='M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z' fill='%23eb2835'%3E%3C/path%3E%3Ccircle fill='%23eb2835' cx='16' cy='16' r='6'%3E%3C/circle%3E%3C/svg%3E");
    
  }
  li.hit.skipped::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Cpath d='M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4zm0-4C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0z' fill='%23cfcfcf'%3E%3C/path%3E%3Ccircle fill='%23cfcfcf' cx='16' cy='16' r='6'%3E%3C/circle%3E%3C/svg%3E");
    
  }
}