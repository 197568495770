@import "../styles/variables.scss";

.fg_item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: solid 1px lightgray;
  color: $gray-9;
}

.fg_item__subtitle {
  color: $gray-7;
  font-weight: bolder;
}

.fg_item__icon {
  color: $gray-9;
}
