$body-bg: #f6f6f6;
$font-family-sans-serif: "Proxima Nova", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$primary-color: rgb(71, 177, 161);
$tertiary-color: #004e6e;
$tertiary-highlight: lighten($tertiary-color, 20%);
$tertiary-highlight-2: lighten($tertiary-color, 37%);
$tertiary-highlight-3: lighten($tertiary-color, 45%);
$primary-highlight: lighten($primary-color, 20%);
$primary-highlight-2: lighten($primary-color, 37%);
$primary-highlight-3: lighten($primary-color, 45%);

$radius: 8px;
$theme-colors: (
  "primary": rgb(71, 177, 161),
  "tertiary": #004e6e,
  "danger": #ff4136,
  "textGray": #707070,
  "highlight": lighten($primary-color, 20%),
);
$gray: #d5d5d5;
$gray-7: #595959;
$gray-8: #343d3a;
$gray-9: #262626;
$dark-blue: #004e6e;
$white: #ffffff;
$font-weight-bold: 600;
$transition: 0.25s;
$enable-responsive-font-sizes: true;
$shadow-left: -6px 0 10px -2px #c0c0c0;
@media screen and (max-width: 1100px) {
  $enable-responsive-font-sizes: true;
  * {
    font-size: 12px;
  }
}
